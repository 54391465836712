import React from "react";
import styled from "styled-components";
import { GlobalStyle, colors, breakpoints } from "./styles/theme";
import { PageHeader } from "./components/PageHeader";
import SABLogoImg from "./resources/images/sab-logo.svg";
import LGPSLogoImg from "./resources/images/lgps-logo.svg";
import { ContactInfo } from "./components/ContactInfo";
import BlueLine from './resources/images/line.svg';
import BulletPoint from './resources/images/bulletpoint.svg';

const LINK_TO_FAQ = `${process.env.PUBLIC_URL}/docs/FrequentlyAskedQuestions.pdf`

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  @media(min-width: ${breakpoints.sm}px) {
    margin-bottom: 42px;
  }
  @media(min-width: ${breakpoints.lg}px) {
    flex-direction: row;
    margin-bottom: 22px;
  }
`;

const IntroText = styled.div`
  font-size: 20px;
  line-height: 29px;
  @media(min-width: ${breakpoints.sm}px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media(min-width: ${breakpoints.lg}px) {
    font-size: 22px;
    max-width: 778px;
    padding-right: 80px;
  }
  a {
      text-decoration: none;
      color: ${colors.darkGrey};
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
        color: ${colors.linkBlue};
      }
    }
`;

const ContentContainer = styled.div`
  padding: 30px 20px 35px 20px;
  @media(min-width: ${breakpoints.sm}px) {
    padding: 71px 57px 95px 76px;
    max-width: 1440px;
    box-sizing: border-box;
    margin: auto;
  }
`;

const ClientLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  @media(min-width: ${breakpoints.sm}px) {
    margin-top: 10px;
    margin-bottom: 64px;
  }
  @media(min-width: ${breakpoints.lg}px) {
    justify-content: flex-start;
    width: auto;
    margin-bottom: auto;
    width: 454px;
    min-width: 454px;
  }
`;

const SABLogo = styled.img`
  width: 159px;
  margin-right: 45px;
  @media(min-width: ${breakpoints.sm}px) {
    width: 212px;
    margin-right: 37px;
  }
  @media(min-width: ${breakpoints.lg}px) {
    margin-right: 56px;
  }
`;
const LGPSLogo = styled.img`
  width: 128px;
  @media(min-width: ${breakpoints.sm}px) {
    width: 171px;
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  border: 0;
  background: transparent;
  text-decoration: none;
  color: ${colors.white};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media(min-width: ${breakpoints.lg}px) {
    flex-direction: row;
  }
`;

const AboutText = styled.div`
  max-width: 778px;
  @media(min-width: ${breakpoints.sm}px) {
    padding-top: 30px;
  }
  @media(min-width: ${breakpoints.lg}px) {
    padding-right: 80px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  padding-left: 10px;
  background: url(${BlueLine}) no-repeat;
  background-position-x: left;
  background-position-y: 11px;
  background-size: 4px 25.5px;
  margin-bottom: 17px;
  padding-top: 8px;
  a {
      text-decoration: none;
      color: ${colors.darkGrey};
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
        color: ${colors.linkBlue};
      }
    }
  @media(min-width: ${breakpoints.sm}px) {
    padding-top: 17px;
    background-position-y: 20px;
  }
`;

const Paragraph = styled.div`
  font-size: 16px;
  line-height: 24px;
  @media(min-width: ${breakpoints.sm}px) {
    font-size: 18px;
    line-height: 28px;
  }
  p {
    margin-bottom: 18px;
    @media(min-width: ${breakpoints.sm}px) {
      margin-bottom: 28px;
    }
    a {
      text-decoration: none;
      color: ${colors.darkGrey};
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
        color: ${colors.linkBlue};
      }
    }
  }
  ul {
    @media(min-width: ${breakpoints.sm}px) {
      margin-left: 45px;
    }
    li {
      margin-bottom: 12px;
      padding-left: 15px;
      background: url(${BulletPoint}) no-repeat;
      background-size: 6px;
      background-position-x: left;
      background-position-y: 11px;
      :last-child {
        margin-bottom: 18px;
        @media(min-width: ${breakpoints.sm}px) {
          margin-bottom: 28px;
        }
      }
    }
  }
`;

export const App = () => {
  return null
  // return (
  //   <PageContainer>
  //     <GlobalStyle />
  //     <PageHeader />
  //     <ContentContainer>
  //       <IntroContainer>
  //         <IntroText>Welcome to the SAB Cost Transparency Compliance and Validation System. The system has been designed and is operated by <a href='https://lgpsboard.org/' target="_blank" rel="noreferrer noopener">The Local Government Pension Scheme Advisory Board</a> to ensure that investment managers who are signatories to the <a href='https://lgpsboard.org/index.php/the-code' target="_blank" rel="noreferrer noopener">Code of Transparency</a> comply with their obligations under the Code. The system is delivered by <a href='https://www.byhiras.com/#about-us' target="_blank">Byhiras</a>.</IntroText>
  //         <ClientLinks>
  //           <StyledLink href='https://lgpsboard.org/' target="_blank" rel="noreferrer noopener">
  //             <SABLogo src={SABLogoImg}/>
  //           </StyledLink>
  //           <StyledLink href='https://www.lgpsboard.org/index.php/the-code' target="_blank" rel="noreferrer noopener">
  //             <LGPSLogo src={LGPSLogoImg} />
  //           </StyledLink>
  //         </ClientLinks>
  //       </IntroContainer>
  //       <BodyContainer>
  //         <AboutText>
  //               <SectionTitle>
  //                 The Code
  //               </SectionTitle>
  //               <Paragraph>
  //                 <p>
  //                   The Code was launched in 2017 to provide transparent and consistent investment cost and fee information between investment managers and administering authorities of pension funds within the Local Government Pension Scheme.  Compliance with the Code requires managers to complete and submit the appropriate template (for their mandate and asset type) without request to their LGPS clients (whether individual funds or pooled entities).
  //                 </p>
  //                 <p>
  //                   With certain exceptions, investment managers are required to complete the appropriate template for their mandate within the framework provided by the Cost Transparency Initiative (CTI). Details of this framework can be found on the <a href='https://www.plsa.co.uk/Policy-and-Research-Investment-Cost-Transparency-Initiative' target="_blank" rel="noreferrer noopener">CTI website</a>. Existing Code signatories as at 21 May 2019 may continue to submit the <a href='https://lgpsboard.org/images/CoT/LGPS_Template_FINAL_March_2017.xlsx' target="_blank" rel="noreferrer noopener">LGPS template</a>  format for a period of up to 12 months but are encouraged to move to the CTI framework as soon as they are able.  In addition, private market managers based in the United States may submit the <a href='https://ilpa.org/reporting-template/get-template/' target="_blank" rel="noreferrer noopener">ILPA template</a>.
  //                 </p>
  //               </Paragraph>
  //               <SectionTitle>
  //                 The System
  //               </SectionTitle>
  //               <Paragraph>
  //                 <p>
  //                   The system, which has been designed by the SAB and delivered by <a href='https://www.byhiras.com/#about-us' target="_blank">Byhiras</a>, will validate and store the information disclosed by investment managers to administering authorities through the submission of templates.  The system provides administering authorities with detailed information on costs and performance from different investment managers through a single service, which will help administering authorities to deliver cost savings and improve investment outcomes.
  //                 </p>
  //                 <p>
  //                   The system provides a range of benefits for investment managers and administering authorities, including:
  //                 </p>
  //                 <ul>
  //                   <li>
  //                     Investment managers are able to evidence their compliance with the Code through a single online portal.
  //                   </li>
  //                   <li>
  //                     Templates can be uploaded in CTI, CTI machine readable, LGPS and ILPA formats.
  //                   </li>
  //                   <li>
  //                     Administering authorities can access all the data disclosed through templates provided by their investment managers through the same portal.
  //                   </li>
  //                   <li>
  //                     Administering authorities have a range of reporting and comparison tools.
  //                   </li>
  //                 </ul>
  //                 <p>
  //                   The system has been developed with a focus on security and confidentiality of data.  Template data will not be seen by the SAB or disclosed to any third party without the consent of the investment manager and the administering authority.
  //                 </p>
  //                 <p>
  //                   Further information about the Code and the system can be found <a href='https://lgpsboard.org/index.php/structure-reform/cost-transparency' target="_blank" rel="noreferrer noopener">here</a>.
  //                 </p>
  //                 <p>
  //                   The SAB has also provided responses to a series of frequently asked questions which can be found <a href={LINK_TO_FAQ} target="_blank" rel="noreferrer noopener">here</a>.
  //                 </p>
  //               </Paragraph>
  //               <SectionTitle>
  //                 About <a href='https://www.byhiras.com/#about-us' target="_blank">Byhiras</a> 
  //               </SectionTitle>
  //               <Paragraph>
  //                 <p>
  //                 <a href='https://www.byhiras.com/#about-us' target="_blank">Byhiras</a> provides a solution to transparency and accountability in investments.  It aggregates data at the most granular level from source, validates the data and enables users to disseminate proprietary information throughout the investment management supply chain.
  //                 </p>
  //                 <p>
  //                 <a href='https://www.byhiras.com/#about-us' target="_blank">Byhiras</a> gives investors the information and tools to evaluate investments and enables managers and other service providers to differentiate their services and demonstrate their value.
  //                 </p>
  //               </Paragraph>
  //         </AboutText>
  //         <ContactInfo />
  //       </BodyContainer>
  //     </ContentContainer>
  //   </PageContainer>
  // );
};
