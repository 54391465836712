import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const colors = {
    white: "#FFFFFF",
    lighterBlue: "#456D9B",
    darkGrey: '#333333',
    lightBeige: '#F7F7F7',
    linkBlue: '#2E6693',
};

export const breakpoints = {
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1440,
}

export const GlobalStyle = createGlobalStyle`
    ${reset}

    html, body {
        min-height: 100%;
        height: 100%;
        color: ${colors.darkGrey};
        font-family: "Roboto", sans-serif;
    }
    input, button, a {
        font-family: "Roboto", sans-serif;
    }
    #root {
        height: 100%;
    }
`;

